import { Dropzone, OrderTrackingFileList } from "@components";
import {
  Edit,
  UploadFile as UploadFileIcon,
  VideoFile,
} from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Button,
  Stack,
  Zoom,
  Skeleton,
  useTheme,
  Avatar,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import {
  OrderOut,
  useWebApiV1UpdateOrderMutation,
} from "@providers/hop-ord-server/api";
import SendForReviewDialog from "../SendForReviewDialog/SendForReviewDialog";
import { DiscardDialog } from "..";
import ScorecardIcon from "@components/Icons/ScorecardFileIcon";
import SendIcon from "@mui/icons-material/Send";
import { ScorecardPreviewModal } from "@components/OrderTrackingFileList/ScorecardPreviewModal";
import { WalkthroughPreviewModal } from "@components/OrderTrackingFileList/WalkthroughPreviewModal";
import { ExitPrompt } from "./ExitPrompt";
import { useTrackingFiles } from "./useTrackingFiles";
import { OrderStatus } from "@enums";
interface Props {
  orderId: number;
  orderData?: OrderOut;
}

const OrderTrackingUploadPanel = ({ orderId, orderData }: Props) => {
  const {
    trackingMode,
    setTrackingMode,
    scorecardFiles,
    planWalkthroughFiles,
    invalidFiles,
    handleDropFiles,
    deleteInvalidFile,
    deleteScorecardFile,
    deleteWalkthroughFile,
    discardChanges,
    sendForReview,
  } = useTrackingFiles(orderId);

  const [orderStatusUpdate] = useWebApiV1UpdateOrderMutation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const theme = useTheme();

  if (!orderData) {
    return (
      <Stack flex={1} gap={1} data-testid="loading-scorecard-upload">
        <Skeleton variant="text" width={200} height={45} />
        <Skeleton variant="rounded" height={145} />
        <Skeleton variant="text" width={250} height={32} />
        <Skeleton variant="rounded" height={100} />
        <Stack direction="row-reverse">
          <Skeleton width={200} height={32} variant="rounded" />
        </Stack>
      </Stack>
    );
  }

  const hasFilesToSend =
    scorecardFiles.filter(
      (file) => file.fileStatus === "Uploaded" || file.isDeleted,
    ).length +
      planWalkthroughFiles.filter(
        (file) => file.fileStatus === "Uploaded" || file.isDeleted,
      ).length >
    0;

  const isEditMode = trackingMode === "edit";

  return (
    <Stack
      sx={{ rowGap: "1em", paddingBottom: 2, padding: "0px 1px 2rem 1px" }}
    >
      {trackingMode !== "submitted" && (
        <Dropzone title="Related files" onDrop={handleDropFiles} />
      )}
      {invalidFiles.length !== 0 && (
        <OrderTrackingFileList
          title="Upload failed"
          subtitle="Invalid files will not be sent to the physician"
          files={invalidFiles}
          deleteFile={deleteInvalidFile}
          itemIdBase="invalid-list"
          fileIcon={
            <UploadFileIcon style={{ color: theme.palette.error.main }} />
          }
          avatar={
            <Avatar sx={{ bgcolor: theme.palette.grey[100] }}>
              <ErrorIcon sx={{ color: theme.palette.grey[500] }} />
            </Avatar>
          }
          isEditMode={isEditMode}
        />
      )}
      <OrderTrackingFileList
        title="Scorecards"
        subtitle={`${
          scorecardFiles.filter(
            (file) => file.fileStatus === "Sent" && !file.isDeleted,
          ).length
        } sent`}
        files={scorecardFiles.filter((file) => !file.isDeleted)}
        deleteFile={deleteScorecardFile}
        itemIdBase="scorecard-list"
        filesAreValid
        fileIcon={<ScorecardIcon color={theme.palette.primary.main} />}
        avatar={
          <Avatar sx={{ bgcolor: theme.palette.grey[100] }}>
            <ScorecardIcon color={theme.palette.grey[500]} />
          </Avatar>
        }
        emptyListText="Scorecard will be displayed here once added"
        preview={(file, onClose) => (
          <ScorecardPreviewModal file={file} onClose={onClose} />
        )}
        isEditMode={isEditMode}
      />
      <OrderTrackingFileList
        title="Plan walkthroughs"
        subtitle={`${
          planWalkthroughFiles.filter(
            (file) => file.fileStatus === "Sent" && !file.isDeleted,
          ).length
        } sent`}
        files={planWalkthroughFiles.filter((file) => !file.isDeleted)}
        deleteFile={deleteWalkthroughFile}
        itemIdBase="walkthrough-list"
        filesAreValid
        fileIcon={<VideoFile sx={{ color: theme.palette.primary.main }} />}
        avatar={
          <Avatar sx={{ bgcolor: theme.palette.grey[100] }}>
            <VideoFile sx={{ color: theme.palette.grey[500] }} />
          </Avatar>
        }
        emptyListText="Plan walkthroughs will be displayed here once added"
        preview={(file, onClose) => (
          <WalkthroughPreviewModal file={file} onClose={onClose} />
        )}
        isEditMode={isEditMode}
      />
      {trackingMode === "submitted" && !hasFilesToSend ? (
        <Stack direction="row" spacing={2}>
          {![OrderStatus.COMPLETED, OrderStatus.APPROVED].includes(
            orderData.status,
          ) && (
            <Button
              variant="outlined"
              startIcon={<Edit />}
              onClick={() => setTrackingMode("edit")}
            >
              Edit
            </Button>
          )}
        </Stack>
      ) : (
        <Stack direction="row" spacing={2}>
          {hasFilesToSend && (
            <Zoom in={hasFilesToSend}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setDiscardDialogOpen(true)}
              >
                Discard
              </Button>
            </Zoom>
          )}
          <Button
            variant="outlined"
            onClick={() => setDialogOpen(true)}
            disabled={!hasFilesToSend}
            startIcon={<SendIcon />}
          >
            Send for review
          </Button>
        </Stack>
      )}
      <SendForReviewDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        onConfirm={() => {
          // submit the files
          sendForReview();
          // update the order status to RO review
          orderStatusUpdate({
            orderId: Number(orderId),
            orderIn: { status: OrderStatus.ready_for_ro_approval },
          });
          setDialogOpen(false);
          setOpenToast(true);
        }}
        scorecardFiles={scorecardFiles}
        walkthroughFiles={planWalkthroughFiles}
        orderData={orderData}
      />
      <DiscardDialog
        open={discardDialogOpen}
        handleCancel={setDiscardDialogOpen}
        handleDiscard={() => {
          discardChanges();
          setDiscardDialogOpen(false);
        }}
      />
      <ExitPrompt
        when={hasFilesToSend}
        message="Unsubmitted files will be lost if you leave this page."
      />
      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={() => setOpenToast(false)}
        message="Files sent"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Stack>
  );
};

export default OrderTrackingUploadPanel;
