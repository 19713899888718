import { useState } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import {
  useAutomationIntegrationApiV1ListTemplatesQuery,
  useAutomationIntegrationApiV1ListTemplateDetailsQuery,
  useAutomationIntegrationApiV1GetStructuresQuery,
} from "@providers/hop-ord-server/api";
import { skipToken } from "@reduxjs/toolkit/query/react";

interface Props {
  orderId: number;
}

interface Structure {
  name: string;
  type: string;
}

const PlanningTemplate = ({ orderId }: Props): JSX.Element => {
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>("");

  const { data: templateNames, isLoading: templateNamesLoading } =
    useAutomationIntegrationApiV1ListTemplatesQuery();

  const { data: template } =
    useAutomationIntegrationApiV1ListTemplateDetailsQuery(
      selectedTemplate ? { templateName: selectedTemplate } : skipToken,
    );

  const { data: structures } = useAutomationIntegrationApiV1GetStructuresQuery({
    orderId,
  });

  const getStructureOption = (templateName: string) => {
    const mappedValue = structures?.structures.find(
      (option: Structure) => option?.name == templateName,
    )?.name;
    return mappedValue;
  };

  const templateOptions = templateNames?.map(
    (templateName: { name: string }) => templateName.name,
  );

  const structureOptions = structures?.structures?.map(
    (structureName: { name: string }) => structureName.name,
  );

  if (templateNamesLoading) return <></>;

  return (
    <Stack gap={3}>
      <Autocomplete
        options={templateOptions || []}
        size="small"
        value={selectedTemplate}
        fullWidth
        onChange={(_: any, newValue: string | null) => {
          setSelectedTemplate(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Automated Template" />
        )}
      />
      {selectedTemplate && (
        <Stack gap={3}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {template?.data?.map((data: any) => {
                return (
                  <>
                    <Grid item xs={6}>
                      <div>
                        <Typography>
                          {data?.name} {data?.mandatory && "*"}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <Autocomplete
                        options={structureOptions || []}
                        defaultValue={getStructureOption(data?.name)}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default PlanningTemplate;
