import { OrderStatus as OrderStatusType } from "@providers/hop-ord-server/api";
import { PaletteColour } from "../theme/theme";

export enum Role {
  RO = "role-radiation-oncologist-viewer",
  DOSIMETRIST = "role-planner",
}

export enum ListDataCategory {
  TREATMENT_SITE = "treatmentSite",
  ISODOSE = "isodose",
  LATERALITY = "laterality",
  TECHNIQUE = "technique",
  SEQUENCE = "sequence",
  FREQUENCY = "frequency",
  ENERGY = "energy",
  ORGAN = "organ",
  REJECT_REASON = "reject_reason",
  TREATING_DEPARTMENT = "treating_department",
}

export const DASH_EMPTY_FIELD = "-";

export const OrderStatus: Record<string, OrderStatusType> = {
  DRAFT: "draft",
  SUBMITTED: "submitted",
  IN_PROGRESS: "in_progress",
  ready_for_ro_approval: "ready_for_ro_approval",
  APPROVED: "approved",
  REJECTED: "rejected",
  COMPLETED: "completed",
};

export const orderStatusMapping: { [key in OrderStatusType]: string } = {
  draft: "Draft",
  submitted: "Submitted",
  in_progress: "In Progress",
  ready_for_ro_approval: "Ready for RO approval",
  approved: "Approved",
  rejected: "Rejected",
  completed: "Completed",
};

export const orderStatusColourMapping: {
  [key in OrderStatusType]: PaletteColour;
} = {
  draft: "default",
  submitted: "secondary",
  in_progress: "info",
  ready_for_ro_approval: "warning",
  approved: "success",
  rejected: "error",
  completed: "primary",
};
