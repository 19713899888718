import { useAuth0 } from "@auth0/auth0-react";
import { EventsType, useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const LogoutOnInactivity = ({ idleMinutes }: { idleMinutes: number }) => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const filteredEvents: EventsType[] = [
    "mousedown",
    "touchstart",
    "MSPointerDown",
  ];
  const onIdleHandler = () => {
    // Logout user on inactivity
    logout().finally(() => {
      navigate("/inactivity");
    });
  };

  useIdleTimer({
    onIdle: onIdleHandler,
    timeout: 1000 * 60 * idleMinutes,
    events: filteredEvents,
  });

  return <></>;
};

export default LogoutOnInactivity;
